<script>
  import MultiSelect from "./flowbite-svelte/forms/MultiSelect.svelte";
  import { navigate } from "svelte-routing";
  import { Select, Drawer, CloseButton } from "flowbite-svelte";
  import { sineIn } from "svelte/easing";

  // const API = "http://localhost:8500";
  const API = "https://tajweed-data-backend.vercel.app";

  let drawerHidden = true;
  let transitionParams = {
    x: -320,
    duration: 200,
    easing: sineIn,
  };

  if (localStorage.getItem("fontSize") === null) localStorage.setItem("fontSize", "text-4xl");
  let v4FontSize = localStorage.getItem("fontSize");

  let selectedFontSize;
  if (localStorage.getItem("fontSize") !== null) selectedFontSize = localStorage.getItem("fontSize");

  const fontSizes = [
    { value: "text-3xl", name: "3xl" },
    { value: "text-4xl", name: "4xl" },
    { value: "text-5xl", name: "5xl" },
    { value: "text-6xl", name: "6xl" },
    { value: "text-7xl", name: "7xl" },
  ];

  const colorsObject = [
    // grays
    { id: 1, value: 1, name: "Gray Alif First", code: "B" },
    { id: 2, value: 2, name: "Gray Laam", code: "C" },
    { id: 3, value: 3, name: "Gray Alif Dot", code: "C" },
    { id: 4, value: 4, name: "Gray Alif Middle", code: "C" },
    { id: 5, value: 5, name: "Gray + Colors", code: "C" },
    { id: 6, value: 6, name: "Gray Tanwin+N No", code: "C" },
    { id: 7, value: 7, name: "Gray H SameSpell", code: "C" },
    { id: 8, value: 8, name: "Gray C DiffCloseSpell", code: "C" },

    // maroons
    { id: 9, value: 9, name: "Maroon HeavyW", code: "D" },
    { id: 10, value: 10, name: "Maroon ALM", code: "D" },
    { id: 11, value: 11, name: "Maroon Merge", code: "D" },

    // reds
    { id: 12, value: 12, name: "Red Full ME", code: "J" },
    { id: 13, value: 13, name: "Red Extra ME", code: "J" },
    { id: 14, value: 14, name: "Red Tiny", code: "J" },

    // oranges
    { id: 15, value: 15, name: "Orange Plain", code: "E" },
    { id: 16, value: 16, name: "Orange Sukoon", code: "E" },

    // golds
    { id: 17, value: 17, name: "Gold Alif", code: "F" },
    { id: 18, value: 18, name: "Gold Tiny", code: "F" },

    // greens
    { id: 19, value: 19, name: "Green* Tanwin+N No*", code: "G" },
    { id: 20, value: 20, name: "Green* SameEndStart", code: "G" },
    { id: 21, value: 21, name: "Green* Noon W", code: "G" },
    { id: 22, value: 22, name: "Green* Meem W", code: "G" },
    { id: 23, value: 23, name: "Green* PreSame", code: "G" },
    { id: 24, value: 24, name: "GreenGr TinyMeem", code: "M" },
    { id: 25, value: 25, name: "GreenGr Tanwin+N No*", code: "M" },

    // assorted
    { id: 26, value: 26, name: "Navy", code: "H" },
    { id: 27, value: 27, name: "Blue", code: "I" },

    // blacks
    { id: 28, value: 28, name: "Black* EndTanwinFatha", code: "A" },
    { id: 29, value: 29, name: "Black* Tanwin+N No", code: "A" },
    { id: 30, value: 30, name: "Black* ThinNavyLs", code: "A" },

    // shapes/icons
    { id: 31, value: 31, name: "Shapes", code: "L" },
    { id: 32, value: 32, name: "Different Ayah Icon", code: "Y" },
  ];

  let wordValues = {};
  let localWordValues = {};
  let localWordUpdateTimestamps = {};
  let page = 1;

  const params = new URLSearchParams(window.location.search);
  if (params.get("page") !== null) page = params.get("page");

  let fetchData;

  $: {
    navigate(`/get?page=${page}`, { replace: true });

    fetchData = (async () => {
      const response = await fetch(`${API}/v1/tajweed/get?page=${page}`);
      const data = await response.json();
      return data;
    })();
  }

  $: {
    if (selectedFontSize) {
      v4FontSize = selectedFontSize;
      localStorage.setItem("fontSize", selectedFontSize);
    }
  }

  async function updateWordColors(key) {
    if (isTokenValid()) {
      if (typeof wordValues[key] !== "undefined" && wordValues[key].length > 0) {
        (async () => {
          const timestamp = new Date().toISOString();
          const response = await fetch(`${API}/v1/tajweed/update?action=add&key=${key}&colors=${wordValues[key].toString()}&timestamp=${timestamp}&token=${localStorage.getItem("tajweedSiteToken")}`);
          const data = await response.json();

          if (data.success === true) {
            updateMessage(key, "success.");
            localWordValues[key] = wordValues[key];
            localWordUpdateTimestamps[key] = timestamp;
          } else {
            updateMessage(key, "error.");
          }
        })();
      }
    }
  }

  async function resetWordColors(key) {
    if (isTokenValid()) {
      (async () => {
        const timestamp = new Date().toISOString();
        const response = await fetch(`${API}/v1/tajweed/update?action=remove&key=${key}&timestamp=${timestamp}&token=${localStorage.getItem("tajweedSiteToken")}`);
        const data = await response.json();

        if (data.success === true) {
          updateMessage(key, "success.");
          wordValues[key] = [];
          localWordValues[key] = wordValues[key];
          localWordUpdateTimestamps[key] = timestamp;
        } else {
          updateMessage(key, "error.");
        }
      })();
    }
  }

  function updateMessage(key, message) {
    document.getElementById(`message-${key}`).innerText = message;

    setTimeout(function () {
      document.getElementById(`message-${key}`).innerText = "";
    }, 2000);
  }

  function saveToken() {
    const token = document.getElementById("token").value;
    localStorage.setItem("tajweedSiteToken", token);
    location.reload();
  }

  function isTokenValid() {
    const token = localStorage.getItem("tajweedSiteToken");
    if (token === null) return false;
    if (token.length !== 20) return false;
    return true;
  }

  function timeAgo(input) {
    const date = input instanceof Date ? input : new Date(input);
    const formatter = new Intl.RelativeTimeFormat("en");
    const ranges = {
      years: 3600 * 24 * 365,
      months: 3600 * 24 * 30,
      weeks: 3600 * 24 * 7,
      days: 3600 * 24,
      hours: 3600,
      minutes: 60,
      seconds: 1,
    };
    const secondsElapsed = (date.getTime() - Date.now()) / 1000;
    for (let key in ranges) {
      if (ranges[key] < Math.abs(secondsElapsed)) {
        const delta = secondsElapsed / ranges[key];
        return formatter.format(Math.round(delta), key);
      }
    }
  }
</script>

<svelte:head>
  <title>Page {page} - QuranWBW.com</title>
</svelte:head>

<Drawer transitionType="fly" {transitionParams} bind:hidden={drawerHidden} id="sidebar1" class="z-[9999999]">
  <div class="flex items-center mb-6">
    <h5 id="drawer-label" class="inline-flex items-center text-4xl mb-4 font-semibold text-gray-500">Settings</h5>
    <CloseButton on:click={() => (drawerHidden = true)} class="mb-4 dark:text-white" />
  </div>

  <div class="flex flex-col space-y-6 justify-left text-left items-left">
    <div class="flex flex-col space-y-2 justify-left">
      <span class="w-max">Font Size</span>
      <Select class="mt-2 w-full" items={fontSizes} bind:value={selectedFontSize} />
    </div>

    <div class="border border-t-1"></div>

    <div class="flex flex-col space-y-2 justify-left">
      <span class="w-max">Token</span>
      <input id="token" type="password" class="w-full rounded-lg" value={localStorage.getItem("tajweedSiteToken")} placeholder="token here..." />
      <button class="px-6 py-2 bg-gray-200 text-sm rounded-3xl" on:click={() => saveToken()}>Set Token</button>
    </div>
  </div>
</Drawer>

<div class="max-w-screen-2xl mx-auto space-y-8 px-2 md:px-6 py-6">
  <div class="flex flex-row justify-between items-center">
    <div class="flex flex-row space-x-2 items-center">
      <button class="bg-gray-200 rounded-3xl p-2" on:click={() => (drawerHidden = false)}>
        <svg class="w-6 h-6 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
          <path stroke="currentColor" stroke-linecap="round" stroke-width="2" d="M5 7h14M5 12h14M5 17h14" />
        </svg>
      </button>

      <div>Page {page}</div>
    </div>

    <div class="flex flex-row space-x-4 text-base text-black justify-left">
      {#if page > 1}
        <button on:click={() => (page = +page - 1)} class="rounded-3xl bg-gray-200 px-4 py-2">← {+page - 1}</button>
      {/if}

      {#if page < 604}
        <button on:click={() => (page = +page + 1)} class="rounded-3xl bg-gray-200 px-4 py-2">{+page + 1} →</button>
      {/if}
    </div>
  </div>

  <!-- words table -->
  <div id="verse">
    {#await fetchData}
      Loading page {page}...
    {:then fetchData}
      <div class="flex">
        <div class="relative max-h-[85vh] w-[-webkit-fill-available] overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead class="text-xs text-gray-700 bg-gray-50 sticky top-0 z-40">
              <tr>
                <th scope="col" class="px-6 py-3"> id </th>
                <th scope="col" class="px-6 py-3 text-center"> qpc_v4 </th>
                <th scope="col" class="px-6 py-3 w-52"> colors </th>
              </tr>
            </thead>
            <tbody class="max-h-[75vh]">
              {#each Object.entries(fetchData.results) as [key, value]}
                <tr class="odd:bg-white even:bg-gray-50 border-b text-left">
                  <td class="px-6 py-4"> {value["word_id"]} </td>
                  <!-- <td class="px-6 py-4"> {value["word_key"]} </td> -->
                  <td id="word-{value['word_id']}" class="px-6 py-4">
                    <div class="flex flex-col space-y-8 pt-4 text-center">
                      <span class="{v4FontSize} p{value['word_page']}">{value["word_qpc_v4"]}</span>

                      <!-- action buttons -->
                      {#if isTokenValid()}
                        <div class="text-left mx-auto">
                          <div class="flex flex-row space-x-2">
                            <MultiSelect items={colorsObject} bind:value={wordValues[value["word_id"]]} let:item let:clear size="sm" placeholder="select colors..." class="min-w-40 max-w-40 md:min-w-52 md:max-w-52" />
                          </div>

                          <div class="flex flex-row space-x-2 text-xs pt-2">
                            <button on:click={() => updateWordColors(value["word_id"])} class="text-xs bg-gray-600 hover:bg-gray-800 text-white px-3 py-2 rounded-3xl">Update</button>
                            <button on:click={() => resetWordColors(value["word_id"])} class="text-xs bg-red-600 hover:bg-red-800 text-white px-3 py-2 rounded-3xl">Reset</button>
                          </div>

                          <div id="message-{value['word_id']}" class="mt-4"></div>
                        </div>
                      {/if}
                    </div>
                  </td>

                  <td class="px-6 py-4 text-left min-w-max">
                    <div class="flex flex-col space-y-2 min-w-max">
                      <div>
                        {#if localWordValues[value["word_id"]] !== undefined}
                          {#each localWordValues[value["word_id"]] as ids}
                            <span class="tajweed-rules">{colorsObject[ids - 1].code}</span>
                            {colorsObject[ids - 1].name} <br />
                          {/each}
                        {:else if value["color_ids"] !== null}
                          {#each value["color_ids"].split(",") as ids}
                            <span class="tajweed-rules">{colorsObject[ids - 1].code}</span>
                            {colorsObject[ids - 1].name} <br />
                          {/each}
                        {/if}
                      </div>

                      {#if value["color_ids"] !== null || localWordValues[value["word_id"]] !== undefined}
                        <div class="text-xs">
                          {#if localWordUpdateTimestamps[value["word_id"]] || localWordUpdateTimestamps[value["word_id"]] !== undefined}
                            <span
                              ><b>you</b> modified
                              {timeAgo(localWordUpdateTimestamps[value["word_id"]]) === undefined ? "just now" : timeAgo(localWordUpdateTimestamps[value["word_id"]])}
                            </span>
                          {:else}
                            <span>
                              {#if value["modified_by"] !== null}
                                <b>{value["modified_by"]}</b>
                              {/if}
                              modified {timeAgo(value["modified_on"])}
                            </span>
                          {/if}
                        </div>
                      {/if}
                    </div>
                  </td>
                </tr>
              {/each}
            </tbody>
          </table>
        </div>
      </div>
    {:catch error}
      <p>{error}</p>
    {/await}
  </div>
</div>
